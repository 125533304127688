.variants-container {
  margin-top: 32px;
}

.card {
  font-weight: 300;
  line-height: 130%;
}

.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;

  b {
    width: 212px;
  }

  pre {
    margin-top: 64px;
  }
}

.secondary-title {
  margin-top: 64px;
}

.secondary-subtitle {
  margin-top: 48px;
  width: 927px;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
}

.tile {
  margin-top: 64px;
}

@media screen and (max-width: 979px) {
  .secondary-subtitle {
    width: 90%;
  }
}

@media screen and (max-width: 320px) {
  .subtitle {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .title {
    font-size: 46px;
  }
}