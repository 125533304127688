.secondary-title {
  margin-top: 64px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas {
  border-radius: 16px;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.08), 0px 8px 10px 1px rgba(0, 0, 0, 0.05), 0px 5px 5px -3px rgba(0, 0, 0, 0.16);
  width: 658px;
  height: 472px;
  text-wrap: wrap;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  background-color: white;
  overflow: hidden;
  margin: 0;
  margin-bottom: 32px;
  margin-top: 16px;
  position: relative;
}

.text {
  margin: 0;
  margin-top: 16px;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
}

.copy {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

@media screen and (max-width: 706px) {
  .container {
    width: 90%;
  }

  .canvas {
    width: 100%;
  }
}