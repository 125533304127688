.container {
  padding: 24px;
  border-radius: 10px;
  width: 564px;
  border: 1px solid var(--default-opaque-neutrals-gray-30, #ACADAF);
  background: #FFF;
  display: flex;
  gap: 24px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
}

.img {
  img {
    height: 120px;
  }
}

@media screen and (max-width: 626px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 460px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}